<template>
    <div class="top-main">
        <div class="divFlex container tm-body">
            <div class="left">
                <img src="@/assets/images/logo.png" alt="">
            </div>
            <div class="right">
                <span :class="menuInfo.tab == '0'?'middle active':'middle'" @click="jumpLink(0)">HOME</span>
                <span :class="menuInfo.tab == '1'?'middle active':'middle'" @click="jumpLink(1)">拉菲古堡</span>
                <span :class="menuInfo.tab == '2'?'middle active':'middle'" @click="jumpLink(2)">玛歌酒庄</span>
                <span :class="menuInfo.tab == '3'?'middle active':'middle'" @click="jumpLink(3)">罗曼尼康帝</span>
                <div class="middle icon1"><el-icon size="20"><Search style="width: 1.6em;height: 1.6em;"/></el-icon></div>
                <div class="middle icon1"><el-icon size="22"><ShoppingCart  style="width: 1.6em;height: 1.6em;"/></el-icon></div>
            </div>
        </div>
        <div class="divFlex tm-body1">
            <img class="sort" src="@/assets/images/sort.png" alt="" @click="mobileTkShowClick">
            <img class="logo" src="@/assets/images/logo.png" alt="">
            <div class="middle icon1"><el-icon size="22"><ShoppingCart  style="width: 1.6em;height: 1.6em;"/></el-icon></div>
            <div class="sort-main" v-if="menuInfo.mobileTkShow" @click="menuInfo.mobileTkShow = false">
                <div class="sm-body">
                    <div class="title">菜单</div>
                    <div  :class="menuInfo.tab == '0'?'body active':'body'" @click="jumpLink(0)">HOME</div>
                    <div  :class="menuInfo.tab == '1'?'body active':'body'" @click="jumpLink(1)">拉菲古堡</div>
                    <div  :class="menuInfo.tab == '2'?'body active':'body'" @click="jumpLink(2)">玛歌酒庄</div>
                    <div  :class="menuInfo.tab == '3'?'body active':'body'" @click="jumpLink(3)">罗曼尼康帝</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import router from '@/router'
import { ref, reactive, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
const store = useStore()

let menuInfo = reactive({
    tab: 0,
    mobileTkShow: false
})

// 跳转  0 飞天茅台酒 1 茅台年份酒 2 茅台定制酒
function jumpLink(e) {
    let index = parseFloat(e)
    menuInfo.tab = index
    if(e == '0') {
        router.push('/')
    } else if(e == '1') {
        router.push('/sort/0')
    } else if(e == '2') {
        router.push('/sort/1')
    } else if(e == '3') {
        router.push('/sort/2')
    }
    store.commit('setMenuIndex', index)
}
function mobileTkShowClick() {
    menuInfo.mobileTkShow =  true
}


onMounted(() => {
    setTimeout(() => {
        menuInfo.tab = store.getters.menuIndex
    }, 200)
})
</script>

<style scoped lang="scss">
.top-main{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    box-shadow: 0 5px 10px rgb(0 0 0 / 5%);
    z-index: 99;
    background-color: #fff;
    .tm-body{
        height: 90px;
        .left{
            padding: 5px 0;
            img{
                width: 180px;
            }
        }
        .right{
            span{
                font-size: 13px;
                padding: 0 10px;
                cursor: pointer;
                font-weight: bold;
                &:hover,&.active{
                    color: rgb(131,183,53);
                }
            }
            .icon1{
                padding: 0 10px;
                cursor: pointer;
            }
        }
    }
    .tm-body1{
        display: none;
    }
}
@media screen and (max-width: 992px) {
    .top-main{
        .tm-body{
            display: none;
        }
        .tm-body1{
            display: flex;
            height: 60px;
            padding: 0 15px;
            .sort{
                width: 16.5px;
            }
            .logo{
                height: 50px;
            }
            .sort-main{
                position: fixed;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 99;
                background-color: rgba($color: #000000, $alpha:.5);
                .sm-body{
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 300px;
                    background-color: #fff;
                    height: 100%;
                    .title{
                        width: 100%;
                        padding: 18px 15px;
                        background-color: rgba(0,0,0,0.04);
                        border-bottom: #61CE70 1px solid;
                        font-weight: bold;
                    }
                    .body{
                        padding: 5px 20px;
                        font-size: 13px;
                        font-weight: bold;
                        line-height: 50px;
                        border-bottom: 1px solid rgba(0,0,0,0.105);
                        &.active{
                            color: #61CE70;
                        }
                    }
                }
            }
        }
    }
}
</style>