import { createRouter,  createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        redirect: '/',
        children: [
            {
                path: "/",
                name: "Index",
                component: () => import('@/views/index/index.vue'),
                meta: { title: "首页", icon: "page"},
            },
            {
                path: "/sort/:id",
                name: "Sort",
                component: () => import('@/views/sort/index.vue'),
                meta: { title: "分类", icon: "page"},
            },
            {
                path: "/detail/:id",
                name: "Detail",
                component: () => import('@/views/detail/index.vue'),
                meta: { title: "详情", icon: "page"},
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory("/"),
    routes
})

export default router