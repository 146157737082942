import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App)

import router from './router'
import store from './store'

import '@/utils/permission'

//多语言
import { createI18n } from 'vue-i18n';
import zh from './assets/lang/zh.js';
import en from './assets/lang/en';
import sa from './assets/lang/sa';
const messages = {
    zh: zh,
    en: en,
    sa: sa
}
const i18n = createI18n({
    locale: 'en', // 当前语言
    fallbackLocale: 'en',
    messages,
    legacy: false
})
// en_US  hi_IN  zh_CN
localStorage.setItem('language', 'en_US');

// 公共样式引入
 import './assets/css/base.scss';

// ElementUI
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}


app.use(ElementPlus).use(i18n).use(store).use(router).mount('#app')
