export default{
    login:{
        t1: "登录",
        t2: "账户",
        t3: "密码",
        t4: "免费注册",
        t5: "用户类型",
        t6: "卡商",
        t7: "普通用户",
        t8: "去登录"
    },
    card: {
        t1: "持卡人名称",
        t2: "卡号",
        t3: "AD卡背面照",
        t4: "AD卡正面照",
        t5: "AD号码",
        t6: "ATM",
        t7: "银行用户ID",
        t8: "银行名称",
        t9: "登录密码",
        t10: "登录账号",
        t11: "绑卡手机号码",
        t12: "支票照片",
        t13: "CIF",
        t14: "联系电话",
        t15: "创建时间",
        t16: "cvv码",
        t17: "邮箱地址",
        t18: "邮箱密码",
        t19: "佣金费率",
        t20: "IFSC码",
        t21: "是否安装otp",
        t22: "PAN卡背面照",
        t23: "PAN卡正面照",
        t24: "PIN码",
        t25: "备注",
        t26: "安全问答信息",
        t27: "卡状态",
        t28: "交易量级",
        t29: "卡用途",
        t30: "UPI照片",
        t31: "使用次数",
        t32: "卡有效期",
        c1: "待审核",
        c2: "正常",
        c3: "使用中",
        c4: "偷钱行为",
        c5: "冻结",
        c6: "飘账",
        c7: "审核失败",
        c8: "无投诉类",
        c9: "有投诉类",
        c10: "承兑",
        c11: "代付"
    },
    requirement: {
        t1: "发布者",
        t2: "佣金费率",
        t3: "完成时间",
        t4: "ID",
        t5: "详细信息",
        t6: "状态",
        t7: "标题",
        t8: "交易量级",
        t9: "资金来源",
        t10: "创建时间",
        t11: "提交申请",
        s1: "发布中",
        s2: "已结束"
    },
    order: {
        o1: "ID",
        o2: "需求标题",
        o3: "佣金费率",
        o4: "佣金(预留)",
        o5: "结算方式",
        o6: "结算卡ID",
        o7: "佣金转账截图",
        o8: "卡商用户名",
        o9: "交易量级",
        o10: "资金来源",
        o11: "订单状态",
        o12: "完成时间",
        o13: "创建时间",
        o14: "设置结算方式",
        o15: "收款账户"
    },
    account: {
        a1: "ID",
        a2: "卡号",
        a3: "银行名称",
        a4: "持卡人名称",
        a5: "银行卡照片",
        a6: "IFSC码",
        a7: "创建时间",
        a8: "usdt地址",
        a9: "usdt收款码图片",
        a10: "账户类型",
        a11: "银行卡",
        a12: "USDT",
        s1: "待审核",
        s2: "使用中",
        s3: "待结算",
        s4: "订单完成",
        s5: "审核失败",
        s6: "结算佣金失败",
        s7: "卡商偷钱",
        s8: "商户用途不匹配"
    },
    button: {
        t1: "查询",
        t2: "重置",
        t3: "编辑",
        t4: "删除",
        t5: "取消",
        t6: "确定",
        t7: "新增",
        t8: "操作",
        t9: "上一页",
        t10: "下一页",
        t11: "是",
        t12: "否"
    },
    tip: {
        t1: "请求成功",
        t2: "请求失败",
        t3: "请求提示",
        t4: "是否确定删除该选项?",
        t5: "请输入值"
    },
    menu: {
        m1: "需求管理",
        m2: "订单管理",
        m3: "银行卡管理",
        m4: "收款账户管理",
        m5: "退出"
    }
}