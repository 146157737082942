export default{
    login: {
        t1: "लॉगिन",
        t2: "खाता",
        t3: "पासवर्ड",
        t4: "निःशुल्क पंजीकरण",
        t5: "उपयोगकर्ता प्रकार",
        t6: "कार्ड डीलर",
        t7: "सामान्य उपयोगकर्ता",
        t8: "लॉग इन पर जाएं"
    },
    card: {
        t1: "कार्डधारक का नाम",
        t2: "कार्ड नंबर",
        t3: "एडी कार्ड बैक फोटो",
        t4: "एडी कार्ड सामने का दृश्य",
        t5: "एडी नंबर",
        t6: "एटीएम",
        t7: "बैंक उपयोगकर्ता आईडी",
        t8: "बैंक का नाम",
        t9: "लॉगिन पासवर्ड",
        t10: "लॉगिन खाता",
        t11: "मोबाइल फ़ोन नंबर बाइंड करें",
        t12: "फोटो जांचें",
        t13: "सीआईएफ",
        t14: "संपर्क नंबर",
        t15: "सृजन समय",
        t16: "सीवीवी कोड",
        t17: "ईमेल पता",
        t18: "ईमेल पासवर्ड",
        t19: "कमीशन दर",
        t20: "आईएफएससी कोड",
        t21: "ओटीपी इंस्टॉल करना है या नहीं",
        t22: "पैन कार्ड बैक फोटो",
        t23: "पैन कार्ड सामने का फोटो",
        t24: "पिन कोड",
        t25: "टिप्पणियाँ",
        t26: "सुरक्षा प्रश्नोत्तर जानकारी",
        t27: "कार्ड स्थिति",
        t28: "लेनदेन परिमाण",
        t29: "कार्ड उद्देश्य",
        t30: "यूपीआई फोटो",
        t31: "उपयोगों की संख्या",
        t32: "कार्ड वैधता अवधि",
        c1: "समीक्षा की जानी है",
        c2: "सामान्य",
        c3: "उपयोग में",
        c4: "पैसा चुराना",
        c5: "फ्रीज",
        c6: "फ्लोटिंग अकाउंट",
        c7: "ऑडिट विफल",
        c8: "कोई शिकायत नहीं",
        c9: "एक शिकायत प्रकार है",
        c10: "स्वीकृति",
        c11: "की ओर से भुगतान करें"
    },
    requirement: {
        t1: "प्रकाशक",
        t2: "कमीशन दर",
        t3: "समापन समय",
        t4: "आईडी",
        t5: "विवरण",
        t6: "स्थिति",
        t7: "शीर्षक",
        t8: "लेनदेन परिमाण",
        t9: "धन का स्रोत",
        t10: "सृजन समय",
        t11: "आवेदन सबमिट करें",
        s1: "प्रकाशन",
        s2: "समाप्त"
    },
    order: {
        o1: "आईडी",
        o2: "आवश्यकता शीर्षक",
        o3: "कमीशन दर",
        o4: "कमीशन (आरक्षित)",
        o5: "निपटान विधि",
        o6: "सेटलमेंट कार्ड आईडी",
        o7: "कमीशन ट्रांसफर का स्क्रीनशॉट",
        o8: "कार्ड व्यापारी उपयोगकर्ता नाम",
        o9: "लेन-देन की मात्रा",
        o10: "धन का स्रोत",
        o11: "आदेश स्थिति",
        o12: "समापन समय",
        o13: "सृजन समय",
        o14: "सेटलमेंट विधि सेट करें",
        o15: "रसीद खाता"
    },
    account: {
        a1: "आईडी",
        a2: "कार्ड नंबर",
        a3: "बैंक का नाम",
        a4: "कार्डधारक का नाम",
        a5: "बैंक कार्ड फोटो",
        a6: "आईएफएससी कोड",
        a7: "सृजन समय",
        a8: "यूएसडीटी पता",
        a9: "USDT भुगतान कोड चित्र",
        a10: "खाता प्रकार",
        a11: "बैंक कार्ड",
        ए12: "यूएसडीटी",
        s1: "लंबित समीक्षा",
        s2: "उपयोग में",
        s3: "लंबित निपटान",
        s4: "ऑर्डर पूरा हुआ",
        s5: "ऑडिट विफल",
        s6: "कमीशन का निपटान करने में विफल",
        s7: "कार्ड डीलर ने पैसे चुराए",
        s8: "व्यापारी उद्देश्य मेल नहीं खाता"
    },
    button: {
        t1: "क्वेरी",
        t2: "रीसेट",
        t3: "संपादित करें",
        t4: "हटाएं",
        t5: "रद्द करें",
        t6: "ठीक है",
        t7: "जोड़ें",
        t8: "ऑपरेशन",
        t9: "पिछला पृष्ठ",
        t10: "अगला पृष्ठ",
        t11: "हाँ",
        t12: "नहीं"
    },
    tip: {
        t1: "अनुरोध सफल",
        t2: "अनुरोध विफल",
        t3: "अनुरोध शीघ्र",
        t4: "क्या आप वाकई इस विकल्प को हटाना चाहते हैं?",
        t5: "कृपया एक मान दर्ज करें"
    },
    menu: {
        m1: "आवश्यकताएँ प्रबंधन",
        m2: "ऑर्डर प्रबंधन",
        m3: "बैंक कार्ड प्रबंधन",
        m4: "रसीद खाता प्रबंधन",
        m5: "बाहर निकलें"
    }
}