import { createStore } from 'vuex'

export default createStore({
    state: {
        platform: "PC",   //  PC  H5
        menuIndex: 0,  //  菜单路由
    },
    getters: {
        platform: state => state.platform,
        menuIndex: state => state.menuIndex,
    },
    mutations: {
        setPlatform(state, type) {
            state.platform = type
        },
        setMenuIndex(state, type) {
            state.menuIndex = type
        },
    },
    actions: {
      
    },
    modules: {
    }
  })