<template>
    <div class="app-main">
        <top-menu />
        <router-view />
        
    </div>
</template>

<script setup>
import topMenu from '@/components/top.vue'
import { ref, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
const store = useStore();

// 创建一个新的watcher对象来监听window对象上的resize事件
const resizeHandler = () => {
    // 处理窗口大小改变后的逻辑
    if(window.innerWidth <= 992) {
        store.commit('setPlatform', 'H5');
    } else {
        store.commit('setPlatform', 'PC');
    }
};
onMounted(() => {
    resizeHandler();
    window.addEventListener('resize', resizeHandler);
})
onUnmounted(() => {
    window.removeEventListener('resize', resizeHandler);
})
</script>

<style lang="scss">
.app-main{
    padding: 90px 0 0;
}


// 产品列表
.im-product{
    padding: 10px 0;
    ul{
        font-size: 0;
        li{
            display: inline-block;
            vertical-align: top;
            width: calc(25% - 15px);
            cursor: pointer;
            text-align: center;
            padding-bottom: 20px;
            box-shadow: 0 5px 10px rgb(0 0 0 / 5%);
            margin: 0 20px 20px 0;
            border-radius: 6px;
            overflow: hidden;
            &:nth-of-type(4n) {
                margin: 0 0 20px 0;
            }
            >div{
                margin-bottom: 5px;
            }
            
            &:hover{
                .ip-img{
                    .tk{
                        opacity: 1;
                    }
                }
            }
            .ip-img{
                width: 100%;
                padding-top: 100%;
                overflow: hidden;
                position: relative;
                >img{
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    -webkit-transform: translate(-50%, -50%);
                    width: 100%;
                    z-index: 1;
                }
                >span{
                    position: absolute;
                    left: 10px;
                    top: 10px;
                    display: block;
                    width: 50px;
                    height: 50px;
                    text-align: center;
                    line-height: 50px;
                    border-radius: 50px;
                    background: #E22D2D;
                    color: #fff;
                    font-size: 12px;
                    z-index: 2;
                }
                .tk{
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 3;
                    background-color: rgba(0,0,0,.5);
                    opacity: 0;
                    transition: all .2s linear;
                    >div{
                        color: #fff;
                    }
                    .read{
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        -webkit-transform: translate(-50%, -50%);
                        padding: 5px 20px;
                        border: 1px solid #fff;
                        font-size: 12px;
                    }
                    .search{
                        position: absolute;
                        right: 10px;
                        top: 10px;
                        color: #fff;
                        img{
                            width: 24px;
                        }
                    }
                }
            }
            .ip-name{
                font-weight: bold;
                height: 40px;
                padding: 0 10px;
                font-size: 13px;
            }
            .ip-category {
                color: #a5a5a5;
                font-size: 13px;
            }
            .ip-number{
                color: rgb(131,183,53);
            }
            .ip-stock{
                color: #ff0000;
            }
            
        }
    }   
}

@media screen and (max-width: 992px) {
    .app-main{
        padding: 60px 0 0;
        .im-product{
            ul{
                li{
                    width: calc(50% - 10px);
                    margin: 0 20px 20px 0;
                    &:nth-of-type(2n) {
                        margin: 0 0 20px 0;
                    }
                    .read{
                        width: 56%;
                    }
                }
            }
        }
    }
}
</style>
