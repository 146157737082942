export default{
    login: {
        t1: "Login",
        t2: "Account",
        t3: "password",
        t4: "Free registration",
        t5: "User type",
        t6: "card dealer",
        t7: "Normal user",
        t8: "Go to log in"
    },
    card: {
        t1: "Cardholder name",
         t2: "card number",
         t3: "AD card back photo",
         t4: "AD card front view",
         t5: "AD number",
         t6: "ATM",
         t7: "Bank user ID",
         t8: "Bank name",
         t9: "Login password",
         t10: "Login account",
         t11: "Bind mobile phone number",
         t12: "Check photo",
         t13: "CIF",
         t14: "Contact number",
         t15: "Creation time",
         t16: "cvv code",
         t17: "email address",
         t18: "Email password",
         t19: "Commission rate",
         t20: "IFSC code",
         t21: "Whether to install otp",
         t22: "PAN card back photo",
         t23: "PAN card front photo",
         t24: "PIN code",
         t25: "Remarks",
         t26: "Safety Q&A information",
         t27: "Card status",
         t28: "Transaction magnitude",
         t29: "Card purpose",
         t30: "UPI Photo",
         t31: "Number of uses",
         t32: "Card validity period",
         c1: "To be reviewed",
         c2: "Normal",
         c3: "In use",
         c4: "stealing money",
         c5: "Freeze",
         c6: "Floating account",
         c7: "Audit failed",
         c8: "No complaints",
         c9: "There is a complaint type",
         c10: "Acceptance",
         c11: "Pay on behalf of"
    },
    requirement: {
        t1: "Publisher",
        t2: "Commission rate",
        t3: "Completion time",
        t4: "ID",
        t5: "Details",
        t6: "status",
        t7: "Title",
        t8: "Transaction magnitude",
        t9: "Source of funds",
        t10: "Creation time",
        t11: "Submit application",
        s1: "Publishing",
        s2: "Ended"
    },
    order: {
        o1: "ID",
        o2: "Requirement title",
        o3: "Commission rate",
        o4: "Commission (reserved)",
        o5: "Settlement method",
        o6: "Settlement Card ID",
        o7: "Screenshot of commission transfer",
        o8: "Card Merchant Username",
        o9: "Transaction volume",
        o10: "Source of funds",
        o11: "Order Status",
        o12: "Completion time",
        o13: "Creation time",
        o14: "Set settlement method",
        o15: "Receipt Account"
    },
    account: {
        a1: "ID",
        a2: "Card number",
        a3: "Bank name",
        a4: "Cardholder Name",
        a5: "Bank card photo",
        a6: "IFSC code",
        a7: "Creation time",
        a8: "usdt address",
        a9: "usdt payment code picture",
        a10: "Account type",
        a11: "Bank Card",
        a12: "USDT",
        s1: "pending review",
        s2: "In use",
        s3: "pending settlement",
        s4: "Order completed",
        s5: "Audit failed",
        s6: "Failed to settle commission",
        s7: "Card dealer stole money",
        s8: "Merchant purpose does not match"
    },
    button: {
        t1: "query",
        t2: "Reset",
        t3: "Edit",
        t4: "Delete",
        t5: "Cancel",
        t6: "OK",
        t7: "Add",
        t8: "Operation",
        t9: "Previous page",
        t10: "Next page",
        t11: "Yes",
        t12: "No"
    },
    tip: {
        t1: "Request successful",
        t2: "Request failed",
        t3: "Request prompt",
        t4: "Are you sure you want to delete this option?",
        t5: "Please enter a value"
    },
    menu: {
        m1: "Requirements Management",
        m2: "Order Management",
        m3: "Bank Card Management",
        m4: "Receipt Account Management",
        m5: "Exit"
    }
}

